import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Layout } from "../../components/layout";
import SEO from "../../components/seo";
import { Header } from "../../components/header";
import { Vita } from "../../components/vita";
import { PresseKontakt } from "../../components/presse-kontakt";
import { MaxWidth } from "../../utils/style";
import { node } from "prop-types";
import { PodcastEntry } from "../../templates/podcast";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulPodcast {
        nodes {
          title
          link
          published
          image {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          description {
            json
          }
          mp3 {
            file {
              url
            }
          }
          podcastUrls
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Meine Kompetenzen"
        keywords={[`gatsby`, `application`, `react`]}
      />
      <Header small>
        <>
          <div className="circle-text">
            <h1>
              Podcast
              <br />
              Archiv
            </h1>
          </div>
          <div
            className="circle"
            style={{
              backgroundImage: `url(${require("../../images/archive.jpg")})`
            }}
          />
        </>
      </Header>

      <MaxWidth greyGradient>
        <h2 style={{ textAlign: "center", margin: "3rem" }} />

        {(data.allContentfulPodcast.nodes || []).map(podcast => (
          <>
            <PodcastEntry podcast={podcast} />
            <div style={{ marginBottom: "3rem" }} />
          </>
        ))}
      </MaxWidth>
    </Layout>
  );
};

export default IndexPage;
